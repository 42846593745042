import { useState, useEffect, useCallback } from 'react';
import { useFormContext, Controller, Control } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import { AnimatePresence, motion } from 'framer-motion';
import { findInputError, isFormInvalid } from '@/utils/formValidation';
import eye from '@/assets/icon/eye-line.svg';

const framer_error = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
};

interface InputErrorProps {
  message: string;
}

export const InputError: React.FC<InputErrorProps> = ({ message }) => {
  return (
    <motion.div
      className="text-xs text-danger-500 mt-2"
      {...framer_error}
    >
      {message}
    </motion.div>
  );
};

interface InputProps {
  name: string;
  label: string;
  type?: string;
  id: string;
  placeholder: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  prefix?: string;
  validation: any;
  multiline?: boolean;
  rows?: number;
  className?: string;
}

export const Input: React.FC<InputProps> = ({
  name,
  label,
  type = 'text',
  id,
  placeholder,
  value,
  disabled,
  readOnly,
  prefix,
  validation,
  multiline = false,
  rows = 5,
  className = '',
}) => {
  const { register, formState: { errors } } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const inputStyle = 'border border-general-200 w-full py-3 px-4 rounded-lg [&[readonly]]:pointer-events-none';
  const normalStyle = 'bg-white placeholder-general-300 text-general-900';
  const disabledStyle = 'bg-general-100 placeholder-general-300 text-general-400';
  const numberStyle = 'appearance-none -moz-appearance-none -webkit-appearance-none';

  const isPassword = (type === 'password');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const updateTextareaHeight = useCallback(() => {
    const textarea = document.getElementById(id) as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [id]);

  const handleTextareaChange = () => {
    updateTextareaHeight();
  };

  const textareaStyle: React.CSSProperties = {
    minHeight: `calc(${rows * 1.5}rem + 2px)`,
    height: 'auto',
  };

  useEffect(() => {
    updateTextareaHeight();
  }, [updateTextareaHeight]);

  useEffect(() => {
    if (value) {
      const inputs = document.querySelectorAll(`#${id}`) as NodeListOf<HTMLInputElement>;
      inputs.forEach(item => {
        item.focus();
        const to = setTimeout(() => item.blur(), 200);
        return () => clearTimeout(to);
      })
    }
  }, [value, id]);

  return (
    <fieldset className={`mb-6 last:mb-0 ${className}`}>
      <label htmlFor={id} className="inline-block text-sm mb-2">
        {label}
      </label>
      {multiline ?
        <textarea
          id={id}
          className={`${inputStyle} ${(disabled || readOnly) ? disabledStyle : normalStyle} overflow-hidden resize-none`}
          placeholder={placeholder}
          defaultValue={value}
          rows={rows}
          disabled={disabled}
          readOnly={readOnly}
          style={textareaStyle}
          onInput={handleTextareaChange}
          {...register(name, validation)}
        ></textarea>
      :
        <div className='relative'>
          {prefix &&
            <div className='absolute top-3 left-4 m-px'>{prefix}</div>
          }
          <input
            id={id}
            type={isPassword ? showPassword ? 'text' : 'password' : type}
            className={`${inputStyle} ${(disabled || readOnly) ? disabledStyle : normalStyle} ${isPassword ? '!pr-12' : ''} ${prefix ? '!pl-14' : ''} ${type === 'number' ? numberStyle : ''}`}
            placeholder={placeholder}
            defaultValue={value}
            disabled={disabled}
            readOnly={readOnly}
            {...register(name, validation)}
          />
          {isPassword && (
            <div
              className={`absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer ${(disabled || readOnly) ? 'pointer-events-none' : ''}`}
              onClick={togglePasswordVisibility}
            >
              <img src={eye} alt='icon' width={22} height={22} className={showPassword ? '' : 'invert contrast-0 opacity-50'} />
            </div>
          )}
        </div>
      }
      <AnimatePresence mode='wait' initial={false}>
        {(isInvalid && inputErrors.error) &&
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        }
      </AnimatePresence>
    </fieldset>
  );
};

interface OptionType {
  value: string;
  label: string;
}

interface InputRadioProps {
  name: string;
  label: string;
  options: OptionType[];
  validation: any;
  selectedOption?: string;
  onOptionChange?: (value: OptionType) => void;
  className?: string;
}

export const InputRadio: React.FC<InputRadioProps> = ({
  name,
  label,
  options,
  validation,
  selectedOption = '',
  onOptionChange = () => {},
  className = ''
}) => {
  const { register, formState: { errors }, setValue, watch } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const currentSelection = watch(name, selectedOption);

  return (
    <fieldset className={`mb-6 last:mb-0 ${className}`}>
      <legend className='inline-block text-sm mb-3'>
        {label}
      </legend>
      <div className='flex flex-wrap flex-col md:flex-row gap-y-3 gap-x-6'>
        {options.map(option => (
          <div key={option.value}>
            <input
              id={`${name}_${option.value}`}
              type='radio'
              value={option.value}
              checked={currentSelection === option.value}
              {...register(name, {
                ...validation,
                onChange: (e) => {
                  const selected = e.target.value;
                  onOptionChange({ value: selected, label: option.label });
                  setValue(name, selected);
                }
              })}
              className='cursor-pointer mr-2 -mt-[2px]'
            />
            <label htmlFor={`${name}_${option.value}`} className='cursor-pointer'>{option.label}</label>
          </div>
        ))}
      </div>
      <AnimatePresence mode='wait' initial={false}>
        {(isInvalid && inputErrors.error) &&
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        }
      </AnimatePresence>
    </fieldset>
  );
};

interface InputSelectProps {
  name: string;
  label: string;
  options: OptionType[];
  placeholder: string;
  notFound: string;
  validation: any;
  control?: Control<any>;
  className?: string;
  isDisabled?: boolean;
  defaultValue?: OptionType;
  onChange?: (value: OptionType | null) => void;
}

export const InputSelect: React.FC<InputSelectProps> = ({ 
  name,
  label,
  options,
  placeholder,
  notFound,
  validation,
  control,
  className = '',
  isDisabled = false,
  defaultValue,
  onChange
}) => {
  const { formState: { errors } } = useFormContext();

  const inputErrors = findInputError(errors, name);
  const isInvalid = isFormInvalid(inputErrors);

  const selectStyle = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isDisabled ? '#f1f5f9' :'white',
      border: '1px solid #e2e8f0',
      borderRadius: '0.5rem',
      fontSize: 'inherit',
      padding: '0.25rem 0.5rem',
      minHeight: 'auto',
      cursor: 'pointer',
      '&:hover': {
        borderColor: 'inherit',
      },
      '&:focus': {
        boxShadow: '0 0 0 2px #2563eb',
      },
      '&:focus-within': {
        boxShadow: '0 0 0 2px #2563eb',
      },
      '&:focus input': {
        border: '0',
        boxShadow: 'none',
      },
      '&:focus-within input': {
        border: '0',
        boxShadow: 'none',
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: '3px 8px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#cbd5e1',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderRadius: '0',
      boxShadow: 'none',
      padding: '0.5rem 0',
      margin: '0',
      overflow: 'hidden',
    }),
    menuList: (provided: any) => ({
      ...provided,
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      border: '1px solid #e2e8f0',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      backgroundColor: (state.isSelected && '#0a91ed') || (state.isFocused && '#eef2f6') || 'transparent',
      color: (state.isSelected && 'white') || (state.isFocused && 'inherit') || 'inherit',
      '&:hover': {
        backgroundColor: state.isSelected ? '#0a91ed' : '#eef2f6',
        color: state.isSelected ? 'white' : 'inherit',
      },
    }),
  };

  return (
    <fieldset className={`mb-6 last:mb-0 ${className}`}>
      <label htmlFor={name} className="inline-block text-sm mb-2">
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        rules={validation}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            {...field}
            options={options}
            placeholder={placeholder}
            noOptionsMessage={() => notFound}
            isDisabled={isDisabled}
            classNamePrefix='react-select'
            styles={selectStyle}
            onChange={(value: SingleValue<OptionType>) => {
              field.onChange(value);
              if (onChange) {
                onChange(value);
              }
            }}
          />
        )}
      />
      <AnimatePresence mode='wait' initial={false}>
        {(isInvalid && inputErrors.error) &&
          <InputError
            message={inputErrors.error.message}
            key={inputErrors.error.message}
          />
        }
      </AnimatePresence>
    </fieldset>
  );
};