import { useState } from 'react';
import { useTitle } from '@/hooks/useEnv';
import { useSessionName } from '@/hooks/useSession';
import { Button } from '@/components/button';
import Popup from '@/components/popup';
import alertQuestion from '@/assets/icon/alert-question.svg';

interface LogoutProps {
  trigger: boolean;
  closePopup: () => void;
}

const Logout: React.FC<LogoutProps> = ({ trigger, closePopup }) => {
  const title = useTitle();
  const sessionName = useSessionName();
  const name = title.toLowerCase().split(' ').join('-');

  const [loadingLogout, setLoadingLogout] = useState(false);
  const [submittingLogout, setSubmittingLogout] = useState(false);

  const logout = () => {
    setLoadingLogout(true);
    setSubmittingLogout(true);
    setTimeout(() => {
      localStorage.removeItem(sessionName);
      localStorage.removeItem('persist:' + name);
      window.location.href = '/login';
    }, 3000);
  };

  const closePopupLogoutButton = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    const parent = target.closest('.ReactModalPortal') as HTMLElement | null;
    if (parent) {
      const button = parent.querySelector('.popup-close') as HTMLButtonElement | null;
      if (button) {
        button.click();
      }
    }
  };

  return (
    <Popup name='Logout Alert' isOpen={trigger} closeButton={'hide'} closeModal={closePopup}>
      <div className='py-4'>
        <img src={alertQuestion} alt='icon' width={120} height={120} className='mx-auto mb-6' />
        <h2 className='text-3xl md:text-4xl font-semibold text-center mb-6'>Logout Akun?</h2>
        <p className='md:text-lg text-center text-general-500'>Apakah Kamu Yakin Akan Logout?</p>
        <div className='flex justify-center gap-4 mt-6'>
          <Button loading={loadingLogout} loadingVariant='danger' onClick={logout} className={`!w-fit border-2 border-danger-500 text-danger-500 lg:hover:bg-danger-500 lg:hover:text-white ${submittingLogout ? 'pointer-events-none' : ''}`}>Ya, Logout</Button>
          <Button onClick={closePopupLogoutButton} className={`!w-fit items-center lg:hover:bg-general-100 ${submittingLogout ? 'pointer-events-none' : ''}`}>Batal</Button>
        </div>
      </div>
    </Popup>
  );
}

export default Logout;