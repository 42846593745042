import { useEffect } from 'react';

const useHtmlClass = (className: string, condition: boolean) => {
  useEffect(() => {
    const htmlElement = document.documentElement;

    if (condition) {
      htmlElement.classList.add(className);
    } else {
      htmlElement.classList.remove(className);
    }

    return () => {
      htmlElement.classList.remove(className);
    };
  }, [className, condition]);
};

export default useHtmlClass;