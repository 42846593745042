import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from './sliceRcbtTable';

export type CbtGenderType = {
    id: number;
    kementrian: string;
    nama_unit: string;
    satuan_kerja: string;
    kl: {
        name: string;
        sub: string;
    };
    kro: Event;
    ro: Event;
    tag_final: any[];
    tingkat_aksi_gender: string;
    dokumen_pendukung: {
        file_name: string;
        path: string;
    }[];
    status_gender: string;
    komponen: {
        data: Details[],
        pagination: Pagination
    };
};

export type RcbtGenderType = {
    id: number;
    nama_pemda: string;
    nama_skpd: string;
    kegiatan: {
        kode: string;
        nama: string
    },
    subkegiatan: {
        kode: string;
        nama: string;
    },
    tag_final: TagApiResponse[],
    status_gender: string;
    updated_at: string;
    disetujui_oleh: User;
    dokumen_pendukung: {
        file_name: string;
        path: string;
    }[];
};

export type GenderDetailComponentType = {
    id: number;
    provinsi: string;
    regency: string;
    pagu: number;
    alokasi: number;
    realisasi: number;
}

export type QueryParamsGender = {
    page: number;
    limit: number;
    kementrian_ids: number[];
    ro_ids: number[];
    kro_ids: number[];
    search: string;
    year: string | null;
    tag_awal: number[];
    tingkat_aksi: string;
    status: string;
    tagRekomendasiHirarkiJenisIds: number[];
    tagRekomendasiHirarkiSektorIds: number[];
    tagRekomendasiHirarkiKategoriIds: number[];
}

type Event = {
    id: string;
    kode: string;
    nama: string;
    satuan: string;
};

export type TagApiResponse = {
    hirarki_jenis: {
        id: number;
        nama: string;
    },
    hirarki_sektor?: {
        id: number;
        nama: string;
    } | null,
    hirarki_kategori?: {
        id: number;
        nama: string;
    } | null
};

type Pagination = {
    total: number;
    per_page: number;
    current_page: number;
    last_page?: number;
}

type Details = {
    id: number;
    komponen: {
        id: number;
        nama: string;
    },
    is_aksi_iklim: boolean;
    is_terkait_gender: boolean;
    jumlah_lokasi: number;
    dokumen_pendukung_gender: {
        file_name: string;
        path: string;
    }[];
    dokumen: {
        file_name: string;
        path: string;
    }[];
    anggaran: {
        pagu: number;
        alokasi: number;
        realisasi: number;
    }
}

export type CbtGenderTableState = {
    kl: boolean;
    kro: boolean;
    ro: boolean;
    tag_final: boolean;
    tag_rekomendasi: boolean;
    tingkat_aksi: boolean;
    dokumen_pendukung: boolean;
    status: boolean;
    get_again: number;
    data: CbtGenderType[];
    pagination: Pagination;
};

export type RcbtGenderTableState = {
    pemda: boolean;
    kegiatan: boolean;
    subKegiatan: boolean;
    tag_iklim: boolean;
    tag_rekomendasi: boolean;
    tingkat_aksi: boolean;
    dokumen_pendukung: boolean;
    status: boolean;
    get_again: number;
    data: RcbtGenderType[];
    pagination: Pagination;
};

const cbtInitialState: CbtGenderTableState = {
    kl: true,
    kro: true,
    ro: true,
    tag_final: true,
    tag_rekomendasi: true,
    tingkat_aksi: true,
    dokumen_pendukung: true,
    status: true,
    get_again: 0,
    data: [],
    pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
    },
};

const rcbtInitialState: RcbtGenderTableState = {
    pemda: true,
    kegiatan: true,
    subKegiatan: true,
    tag_iklim: true,
    tag_rekomendasi: true,
    tingkat_aksi: true,
    dokumen_pendukung: true,
    status: true,
    get_again: 0,
    data: [],
    pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
    },
};

export const genderCbtTableSlice = createSlice({
    name: 'cbt_gender_table',
    initialState: cbtInitialState,
    reducers: {
        setCbtGenderTable: (state, action: PayloadAction<Partial<CbtGenderTableState>>) => {
            return {
                ...state,
                ...action.payload,
                pagination: {
                    ...state.pagination,
                    ...action.payload.pagination,
                },
            };
        },
        clearCbtGenderTable: (state) => {
            return {
                ...cbtInitialState,
                pagination: {
                    ...cbtInitialState.pagination,
                },
            };
        },
    },
});

export const genderRcbtTableSlice = createSlice({
    name: 'rcbt_gender_table',
    initialState: rcbtInitialState,
    reducers: {
        setRcbtGenderTable: (state, action: PayloadAction<Partial<RcbtGenderTableState>>) => {
            return {
                ...state,
                ...action.payload,
                pagination: {
                    ...state.pagination,
                    ...action.payload.pagination,
                },
            };
        },
        clearRcbtGenderTable: (state) => {
            return {
                ...rcbtInitialState,
                pagination: {
                    ...rcbtInitialState.pagination,
                },
            };
        },
    },
});

export const { setCbtGenderTable, clearCbtGenderTable } = genderCbtTableSlice.actions;
export const { setRcbtGenderTable, clearRcbtGenderTable } = genderRcbtTableSlice.actions;