import { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HeadLayout } from '@/layouts/head';
import { getCookie, switchLogoToEn } from '@/layouts/main';
import { useBaseUrl } from '@/hooks/useEnv';
import bg from '@/assets/img/bg.svg';
import logoKemenkeuId from '@/assets/img/logo-kemenkeu-black.svg';
import logoKemenkeuEn from '@/assets/img/logo-kemenkeu-en-black.svg';
import TawkToWidget from '@/components/tawkto';

interface AuthLayoutProps {
  children: ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  useEffect(() => {
    if (getCookie('googtrans')) {
      setTimeout(() => {
        switchLogoToEn();
      },500);
    }
  }, []);

  return (
    <HeadLayout>
      <div className='relative h-screen overflow-hidden'>
        <main className='overflow-y-auto h-full relative z-[1]'>
          <div className='w-full max-w-3xl lg:max-w-4xl mx-auto min-h-full flex items-start sm:items-center justify-center sm:p-8'>
            <div className='w-full bg-white rounded-xl p-6 md:p-10 lg:p-16 flex items-center max-sm:my-2 max-sm:min-h-[90vh]'>
              <div className='w-full'>
                <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
                  {children}
                </GoogleOAuthProvider>
              </div>
            </div>
          </div>
        </main>

        <div className='absolute top-0 left-0 w-full h-full max-sm:hidden'>
          <img src={bg} alt='bg' width={1440} height={1024} className='w-full h-full object-cover' />
        </div>
      </div>

      <TawkToWidget />
    </HeadLayout>
  );
};

export const AuthLayout1: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <AuthLayout>
      <section className='mb-8'>
        <Link to={useBaseUrl()} className='inline-block'>
          <img src={logoKemenkeuId} alt='logo' width={175} height={48} className='w-auto h-14 text-transparent logo-id' />
          <img src={logoKemenkeuEn} alt='logo' width={175} height={48} className='w-auto h-14 text-transparent logo-en hidden' />
        </Link>
      </section>

      {children}
    </AuthLayout>
  );
};

export const AuthLayout2: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <AuthLayout>
      <section className='mb-8 text-center'>
        <Link to={useBaseUrl()} className='inline-block'>
          <img src={logoKemenkeuId} alt='logo' width={175} height={48} className='w-auto h-14 text-transparent logo-id' />
          <img src={logoKemenkeuEn} alt='logo' width={175} height={48} className='w-auto h-14 text-transparent logo-en hidden' />
        </Link>
      </section>

      {children}
    </AuthLayout>
  );
};