import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTitle, useCurrentUrl } from '@/hooks/useEnv';
import Navbar from '@/pages/landing/partial/navbar';
import Footer from '@/pages/landing/partial/footer';

function removeMeta(selector: string) {
  document.querySelectorAll(selector).forEach(item => {
    item.remove()
  })
}

const PublicationDetailPage: React.FC = () => {
  const title = useTitle();
  const currentUrl = useCurrentUrl();
  const { id } = useParams<{ id: string }>();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [publicationLoaded, setPublicationLoaded] = useState(false);
  const [publication, setPublication] = useState<any>();

  useEffect(() => {
    removeMeta('[type="application/ld+json"]:not([data-rh])')
    removeMeta('[name="description"]:not([data-rh])')
    removeMeta('[property="og:description"]:not([data-rh])')
    removeMeta('[name="twitter:description"]:not([data-rh])')
    setTimeout(() => {
      setPageLoaded(true)
    },1000)
  }, []);

  useEffect(() => {
    const fetchPublication = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/publikasi/detail/${id}`);
        
        if (response.ok) {
          const res = await response.json();
          // console.log('sukses',res);
          setPublication(res.data);
        } else {
          // const res = await response.json();
          // console.log('gagal',res);
        }
      } catch (error) {
        // console.log('malfungsi',error);
      } finally {
        setPublicationLoaded(true);
      }
    };

    fetchPublication();
  }, [id]);

  const defaultDescription = 'Climate Budget Tagging (CBT) adalah mekanisme penandaan anggaran belanja untuk mengetahui kapasitas fiskal dalam mendanai kegiatan mitigasi dan adaptasi perubahan iklim.'

  const microdata = `
    {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@id": "https://app.cbt-dev.my.id/#website",
          "@type": "WebSite",
          "inLanguage": "id",
          "name": "CBT Kemenkeu",
          "url": "https://app.cbt-dev.my.id/"
        },
        {
          "@id": "https://app.cbt-dev.my.id/publikasi/${id}#webpage",
          "@type": "WebPage",
          "description": "${publicationLoaded && publication ? publication.deskripsi.length > 155 ? publication.deskripsi.substring(0,155) + '...' : publication.deskripsi : defaultDescription}",
          "name": "${publicationLoaded ? publication ? publication.judul : 'Publikasi Tidak Ditemukan' : 'Rincian Publikasi'}",
          "url": "https://app.cbt-dev.my.id/publikasi/${id}",
          "isPartOf": {
            "@id": "https://app.cbt-dev.my.id/#website"
          },
          "potentialAction": [
            {
              "@type": "ReadAction",
              "target": [
                "https://app.cbt-dev.my.id/publikasi/${id}"
              ]
            }
          ]
        },
        {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Beranda",
              "item": "https://app.cbt-dev.my.id/beranda/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Tentang",
              "item": "https://app.cbt-dev.my.id/tentang/"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Publikasi",
              "item": "https://app.cbt-dev.my.id/publikasi/"
            }
          ]
        }
      ]
    }
  `

  const header = `
    <div class="container py-5 pt-lg-5 pb-lg-3">
      <div class="row mt-5 pt-5">
        <div class="col-lg-8 d-none d-lg-flex ms-auto">
          <nav>
            <ol class="breadcrumb fs-5 flex-nowrap mb-0 opacity-0">
              <li class="breadcrumb-item d-flex align-items-center"><a href="/publikasi/" class="text-decoration-none text-white lh-lg line-clamp line-clamp-1">Publikasi</a></li>
              <li class="breadcrumb-item d-flex align-items-center text-white breadcrumb-category d-none"><span class="lh-lg line-clamp line-clamp-1"></span></li>
              <li class="breadcrumb-item d-flex align-items-center text-white breadcrumb-title active" aria-current="page"><span class="lh-lg line-clamp line-clamp-1"></span></li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  `

  const content = `
    <div class="container py-5">
      <div class="row" id="publication-detail">
        <div class="col-lg-4">
          <div class="text-center pe-lg-4 mb-4 detail-thumbnail">
            <img src="assets/img/publikasi-placeholder_cover_loading.jpg" alt="thumbnail" width="400" height="555" class="w-100 h-auto object-fit-cover rounded-4 bg-light-blue shadow-lg" />
          </div>
        </div>
        <div class="col-lg-8">
          <div class="alert alert-light fst-italic px-4 py-3 rounded-4 border-0">Memuat...</div>
        </div>
      </div>
    </div>
  `

  const other = `
    <div class="container py-5">
      <h2 class="fs-2 fw-semibold mb-5">Publikasi Lainnya</h2>
      <div class="row row-gap-4" id="publication-other">
        <div class="col">
          <div class="alert alert-light fst-italic px-4 py-3 rounded-4 border-0">Memuat...</div>
        </div>
      </div>
    </div>
  `

  return (
    <>
      <Helmet>
        <title>{`${publicationLoaded ? publication ? publication.judul : 'Publikasi Tidak Ditemukan' : 'Rincian Publikasi'} - ${title}`}</title>
        <meta name='description' content={publicationLoaded && publication ? publication.deskripsi.length > 155 ? publication.deskripsi.substring(0,155) + '...' : publication.deskripsi : defaultDescription} />
        <meta property='og:description' content={publicationLoaded && publication ? publication.deskripsi.length > 155 ? publication.deskripsi.substring(0,155) + '...' : publication.deskripsi : defaultDescription} />
        <meta name='twitter:description' content={publicationLoaded && publication ? publication.deskripsi.length > 155 ? publication.deskripsi.substring(0,155) + '...' : publication.deskripsi : defaultDescription} />
        <link rel="canonical" href={currentUrl} />
        <meta property="og:url" content={currentUrl} />
        <meta name="twitter:url" content={currentUrl} />
        <link rel='stylesheet' href='/assets/vendors/bootstrap-5.3.2/css/bootstrap.min.css' />
        <link rel='stylesheet' href='/assets/vendors/bootstrap-icons-1.11.2/font/bootstrap-icons.min.css' />
        <link rel='stylesheet' href='/assets/vendors/remixicon-4.1.0/remixicon.css' />
        <link rel='stylesheet' href='/assets/vendors/flag-icons-7.0.3/css/flag-icons.min.css' />
        <link rel='stylesheet' href='/assets/fonts/poppins/stylesheet.css' />
        <link rel='stylesheet' href='/assets/css/color.css' />
        <link rel='stylesheet' href='/assets/css/style.css' />
        <link rel='stylesheet' href='/assets/css/custom.css?v=2025-02-25' />
        <script src='/assets/vendors/jquery-3.7.1/jquery.min.js'></script>
        <script src='/assets/vendors/bootstrap-5.3.2/js/bootstrap.bundle.min.js'></script>
        <script src='/assets/vendors/simple-aos-4.2.0/aos.js'></script>
        <script src='/assets/vendors/google-translate/initial.js'></script>
        <script src='/assets/vendors/google-translate/run.js'></script>
        <script src='/assets/js/script.js?v=2025-03-14'></script>
        <script src='/assets/js/publikasi-detail.js?v=2025-03-17'></script>
        <script type='application/ld+json'>{microdata}</script>
      </Helmet>
      <div style={{opacity: !pageLoaded ? '0' : '1', transition: 'all .25s ease'}}>
        <Navbar />
        <main className='flex-shrink-0'>
          <header
            className='bg-dark-blue text-white bg-pattern-3'
            data-aos='fade-up'
            dangerouslySetInnerHTML={{ __html: header }}
          />
          <section 
            data-aos='fade-up'
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <section 
            data-aos='fade-up'
            dangerouslySetInnerHTML={{ __html: other }}
          />
        </main>
        <Footer />
      </div>
    </>
  );
}

export default PublicationDetailPage;