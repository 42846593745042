interface ErrorDetail {
  type: string;
  message: string;
}

export function findInputError(errors: any, name: string): { error?: ErrorDetail } {
  const filtered = Object.keys(errors)
    .filter(key => key.includes(name))
    .reduce((cur, key) => {
      return Object.assign(cur, { error: errors[key] });
    }, {} as { error?: ErrorDetail });
  return filtered;
}

export const isFormInvalid = (err: { error?: ErrorDetail }): boolean => {
  return !!err.error;
}
