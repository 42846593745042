import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { Collapse } from '@mantine/core';
import { RiArrowDropDownLine } from '@remixicon/react';

interface SidebarMenuProps {
  parent: string;
  active: string;
  list: {
    link?: string;
    icon: React.ReactNode;
    text: string;
    notif?: number | string;
    isAdmin?: boolean;
    child?: {
      link: string;
      text: string;
      child?: {
        link: string;
        text: string;
      }[];
    }[];
  }[];
}

const findOpenedPaths = (items: any, active: string): string[] => {
  const openedPaths: string[] = [];

  items.forEach((item: any, parentIndex: number) => {
    item.child?.forEach((child: any, childIndex: number) => {
      if (child.link === active) {
        openedPaths.push(parentIndex.toString());
      } else if (child.child?.some((subChild: any) => subChild.link === active)) {
        openedPaths.push(parentIndex.toString(), `${parentIndex}.${childIndex}`);
      }
    });
  });

  return openedPaths;
};

export const SidebarMenu: React.FC<SidebarMenuProps> = ({ parent, active, list }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isAdminPage, setIsAdminPage] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (currentPath.startsWith('/admin')) {
      setIsAdminPage(true);
    }
  }, [currentPath]);

  const openedPaths = findOpenedPaths(list, active);
  const loggedRef = useRef(false);
  const [opened, setOpened] = useState<string[]>([]);
  const [openedInit, setOpenedInit] = useState<string[]>([]);
  const sidebar = useSelector((state: RootState) => state.panel.sidebar);

  useEffect(() => {
    if (!loggedRef.current) {
      if (sidebar) {
        setOpened(openedPaths);
      } else {
        setOpened([]);
      }
      loggedRef.current = true;
    }
  }, [openedPaths, sidebar]);

  const handleToggle = (key: string) => {
    setOpened((prev: any) => 
      prev.includes(key) ? prev.filter((k: any) => k !== key) : [...prev, key]
    );
  };

  useEffect(() => {
    const matchingIndex = list.findIndex(item => {
      if (item.link === active) return true;
      return item.child?.some(child => child.link === active || child.child?.some(subChild => subChild.link === active));
    });

    if (matchingIndex !== -1) {
      const matchingKey = matchingIndex.toString();
      setOpenedInit(prev => [...prev, matchingKey]);
    }
  }, [active, list]);

  useEffect(() => {
    const handleCloseSidebarMenu = (event: MouseEvent) => {
      const asideElement = document.querySelector('aside');
      
      if (asideElement && asideElement.classList.contains('sidebar-fit')) {
        const activeElement = asideElement.querySelector('.sidebar-root-menu.active');

        if (asideElement.contains(event.target as Node)) {
          if (activeElement) {
            if (activeElement.contains(event.target as Node)) {
              return;
            } else {
              setOpened([]);
            }
          }
        } else {
          setOpened([]);
        }
      }
    };

    document.addEventListener('mousedown', handleCloseSidebarMenu);
    return () => {
      document.removeEventListener('mousedown', handleCloseSidebarMenu);
    };
  }, []);

  useEffect(() => {
    if (list) {
      const checkAdmin = list.some(item => !('isAdmin' in item) || item.isAdmin === true);
      setIsVisible(checkAdmin);
    }
  }, [list]);

  return (
    <>
      {isVisible &&
        <div className='font-medium my-5'>
          <div className='uppercase tracking-widest line-clamp-1 text-sm mb-3 sidebar-divide'>
            <span>{parent}</span>
            <hr className='border-primary-500/50 mb-3 hidden' />
          </div>
          <ul className='flex flex-col gap-2'>
            {list.map((item, index) => (
              <React.Fragment key={item.text || index}>
                {(item.isAdmin === undefined || item.isAdmin === true) &&
                  <>
                    {!item.child ?
                      <li className={`transition rounded-lg [&_.sidebar-text]:text-white [&.active_.sidebar-text]:text-primary-700 [&:hover_.sidebar-text]:text-primary-700 [&_svg]:text-white [&.active_svg]:text-primary-700 [&:hover_svg]:text-primary-700 [&.active]:bg-white hover:bg-primary-600 [&:not(.active)_.sidebar-text]:hover:text-white [&:not(.active)_svg]:hover:text-white [&.active]:pointer-events-none ${active === item.link ? 'active' : ''}`}>
                        <Link to={`${isAdminPage ? '/admin' : '/member'}${item.link}`} className='flex justify-center gap-2 p-4'>
                          {React.cloneElement(item.icon as React.ReactElement, {
                            size: 24,
                            className: 'transition shrink-0 opacity-90',
                          })}
                          <div className='flex items-center justify-between gap-2 transition w-full sidebar-text'>
                            {item.text}
                            {item.notif &&
                              <span className='shrink-0 bg-primary-800 text-white text-xs w-7 h-7 rounded-full flex items-center justify-center -m-1'>{item.notif}</span>
                            }
                          </div>
                        </Link>
                      </li>
                    :
                      <li className={`[&_.sidebar-text]:text-white [&.active_.sidebar-text]:text-primary-700 [&:hover_.sidebar-text]:text-primary-700 [&_svg]:text-white [&.active_svg]:text-primary-700 [&:hover_svg]:text-primary-700 [&:not(.active)_.sidebar-text]:hover:text-white [&:not(.active)_svg]:hover:text-white [&.active_.sidebar-text_svg]:-rotate-180 [&.active_.sidebar-parent]:bg-white [&_.sidebar-parent.view]:bg-white [&_.sidebar-parent.view_.sidebar-text]:text-primary-700 [&:hover_.sidebar-parent.view_.sidebar-text]:text-primary-700 [&_.sidebar-parent.view_svg]:text-primary-700 [&:hover_.sidebar-parent.view_svg]:text-primary-700 ${opened.includes(index.toString()) ? 'active' : ''} sidebar-root-menu`}>
                        <div className={`flex justify-center gap-2 p-4 rounded-lg cursor-pointer transition hover:bg-primary-600 sidebar-parent ${openedInit.includes(index.toString()) ? 'view' : ''}`} onClick={() => handleToggle(index.toString())}>
                          {React.cloneElement(item.icon as React.ReactElement, {
                            size: 24,
                            className: 'transition shrink-0 opacity-90',
                          })}
                          <div className='flex items-center justify-between gap-2 transition w-full sidebar-text'>
                            {item.text}
                            <RiArrowDropDownLine size={24} className={`shrink-0 -mr-2 transition`} />
                          </div>
                        </div>
                        <Collapse in={opened.includes(index.toString())}>
                          <ul className='flex flex-col gap-2 [&.active_li]:bg-primary-600 mt-2 overflow-auto hidden-scrollbar sidebar-child'>
                            <li className={`hidden text-white text-xs font-semibold pt-3 pb-2 px-4 opacity-70 sidebar-child-title`}>
                              {item.text}
                            </li>
                            {item.child.map((itemChild, indexChild) => (
                              <React.Fragment key={itemChild.text || indexChild}>
                                {!itemChild.child ?
                                  <li key={itemChild.text || indexChild} className={`pl-12 pr-4 py-3 rounded-lg transition hover:bg-primary-600 [&.active]:bg-primary-600 [&.active]:pointer-events-none cursor-pointer ${active === itemChild.link ? 'active' : ''}`} onClick={(e) => {e.stopPropagation();e.currentTarget.querySelector('a')?.click();}}>
                                    <Link to={`${isAdminPage ? '/admin' : '/member'}${itemChild.link}`} className='block w-full text-white hover:text-white'>
                                      {itemChild.text}
                                    </Link>
                                  </li>
                                :
                                  <li key={itemChild.text || indexChild} className={`!pl-0 ${opened.includes(index.toString() + '.' + indexChild.toString()) ? 'active' : ''}`}>
                                    <div className='flex items-center justify-between gap-2 pl-12 pr-4 py-3 rounded-lg transition hover:bg-primary-600 [&.active]:bg-primary-600 cursor-pointer' onClick={() => handleToggle(index.toString() + '.' + indexChild.toString())}>
                                      {itemChild.text}
                                      <RiArrowDropDownLine size={24} className={`shrink-0 -mr-2 transition !text-white`} />
                                    </div>
                                    <Collapse in={opened.includes(index.toString() + '.' + indexChild.toString())}>
                                      <ul className='flex flex-col gap-2 [&.active_li]:bg-primary-600 mt-2 sidebar-child'>
                                        {itemChild.child.map((itemSubChild, indexSubChild) => (
                                          <li key={itemSubChild.text || indexSubChild} className={`pl-12 pr-4 py-3 rounded-lg transition hover:bg-primary-600 [&.active]:bg-primary-600 [&.active]:pointer-events-none cursor-pointer ${active === itemSubChild.link ? 'active' : ''}`} onClick={(e) => {e.stopPropagation();e.currentTarget.querySelector('a')?.click();}}>
                                            <Link to={`${isAdminPage ? '/admin' : '/member'}${itemSubChild.link}`} className='block w-full text-white hover:text-white ml-4'>
                                              {itemSubChild.text}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </Collapse>
                                  </li>
                                }
                              </React.Fragment>
                            ))}
                          </ul>
                        </Collapse>
                      </li>
                    }
                  </>
                }
              </React.Fragment>
            ))}
          </ul>
        </div>
      }
    </>
  );
};
