import { useSelector } from 'react-redux';
import { RootState } from '@/app/store';
import { useEffect, useState } from 'react';

export const useUser = () => {
  const user = useSelector((state: RootState) => state.user);
  return user;
};

export const useAdmin = () => {
  const user = useUser();
  return user.role === 'admin-data';
};

export const useMember = () => {
  const user = useUser();
  return user.role === 'kontributor-data';
};

export const usePublik = () => {
  const user = useUser();
  return user.role === 'publik-terdaftar';
};

export const useMemberType = () => {
  const user = useUser();
  const [memberType, setMemberType] = useState<string | null>(null);
  const [memberTypeData, setMemberTypeData] = useState({
    id: '',
    name: '',
    value: '',
    image: '',
    catatan: ''
  });

  const fetchMemberType = async (id: string, token: string): Promise<string | null> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_USER}/detail/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
        },
      });
  
      if (response.ok) {
        const result = await response.json();        
        setMemberTypeData({
          id: result.data.type.id,
          name: result.data.type.name,
          value: result.data.type.value,
          image: result.data.type.image,
          catatan: result.data.type.catatan
        })
        return result.data.type.name;
      }
    } catch (error) {
      // console.log('malfungsi',error);
    }
    return null;
  };

  useEffect(() => {
    if (user.role === 'kontributor-data') {
      const getMemberType = async () => {
        const type = await fetchMemberType(user.id, user.token);
        setMemberType(type);
      };
      getMemberType();
    } else {
      setMemberType(null);
    }
  }, [user.role, user.id, user.token]);

  return {
    memberKL: memberType === 'K/L',
    memberProv: memberType === 'Provinsi',
    memberKota: memberType === 'Kabupaten Kota',
    memberData: memberTypeData,
  };
};
