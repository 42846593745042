import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useCurrentUrl } from '@/hooks/useEnv';
import Translate from '@/components/translate';

interface HeadLayoutProps {
  children: ReactNode;
}

export const HeadLayout: React.FC<HeadLayoutProps> = ({ children }) => {
  const currentUrl = useCurrentUrl();

  return (
    <>
      <Helmet>
        <link rel="canonical" href={currentUrl} />
        <meta property="og:url" content={currentUrl} />
        <meta name="twitter:url" content={currentUrl} />
      </Helmet>
      {children}
      <Translate />
    </>
  );
};