import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MitigasiAdaptasiCobenefitState {
  tagging_awal?: boolean;
  tagging_rekomendasi?: boolean;
  tagging_final?: boolean;
  tagging_realisasi?: boolean;
  alokasi?: boolean;
  realisasi?: boolean;
  potensi?: boolean;
  terverifikasi?: boolean;
  pengurangan?: boolean;
};

const initialState: MitigasiAdaptasiCobenefitState = {
  tagging_awal: true,
  tagging_rekomendasi: true,
  tagging_final: true,
  tagging_realisasi: true,
  alokasi: true,
  realisasi: true,
  potensi: true,
  terverifikasi: true,
  pengurangan: true,
};

const mitigasiAdaptasiCobenefitSlice = createSlice({
  name: 'mitigasi_adaptasi_cobenefit_table',
  initialState,
  reducers: {
    setMitigasiAdaptasiCobenefit: (state, action: PayloadAction<Partial<MitigasiAdaptasiCobenefitState>>) => {
      return { ...state, ...action.payload };
    },
    clearMitigasiAdaptasiCobenefit: (state) => {
      return initialState;
    },
  },
});

export const { setMitigasiAdaptasiCobenefit, clearMitigasiAdaptasiCobenefit } = mitigasiAdaptasiCobenefitSlice.actions;
export default mitigasiAdaptasiCobenefitSlice.reducer;