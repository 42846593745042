import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type CBTDetailComponentType = {
    id: number;
    provinsi: string;
    regency: string;
    pagu: number;
    alokasi: number;
    realisasi: number;
  }

type Pagination = {
    total: number;
    per_page: number;
    current_page: number;
    last_page?: number;
}

type DataCbtComponentTableState = {
    lokasi: boolean;
    pagu: boolean;
    alokasi: boolean;
    realisasi: boolean;
    get_again: number;
    data: CBTDetailComponentType[];
    pagination: Pagination;
}

const initialState: DataCbtComponentTableState = {
    lokasi: true,
    pagu: true,
    alokasi: true,
    realisasi: true,
    get_again: 0,
    data: [],
    pagination: {
        total: 0,
        per_page: 10,
        current_page: 1,
    },
};

const dataCbtComponentTableSlice = createSlice({
  name: 'data_cbt_component_table',
  initialState,
  reducers: {
    setDataCbtComponentTable: (state, action: PayloadAction<Partial<DataCbtComponentTableState>>) => {
      return {
        ...state,
        ...action.payload,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    },
    clearDataCbtComponentTable: (state) => {
      return {
        ...initialState,
        pagination: {
          ...initialState.pagination,
        },
      };
    },
  },
});

export const { setDataCbtComponentTable, clearDataCbtComponentTable } = dataCbtComponentTableSlice.actions;
export default dataCbtComponentTableSlice.reducer;
