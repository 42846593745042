import { useEffect } from 'react';

const cookieNameTranslate = 'googtrans';
const cookieValueTranslateEn = '/id/en';

const translating = () => {
  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  };

  const loadScript = (src: string, callback?: () => void) => {
    if (document.querySelector(`script[src="${src}"]`)) {
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => callback && callback();
    script.onerror = () => console.error(`Gagal memuat script: ${src}`);
    document.body.appendChild(script);
  };

  setTimeout(() => {
    const datatable = document.querySelectorAll('.mantine-datatable') as NodeListOf<HTMLElement>;
    datatable.forEach((el) => {
      el.setAttribute('translate', 'no');
    });
  }, 3000);

  setTimeout(() => {
    if (getCookie(cookieNameTranslate)) {
      (window as any).googleTranslateElementInit = () => {
        new (window as any).google.translate.TranslateElement(
          { pageLanguage: 'id', autoDisplay: false },
          'google_translate_element'
        );
      };
  
      loadScript('https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    }
  }, 4000);

  setTimeout(() => {
    const body = document.body as HTMLElement;
    body.removeAttribute('style');
  }, 6000);
};

export const translateToID = () => {
  document.cookie = 'googtrans=; path=/; max-age=0;';
  window.location.reload();
};

export const translateToEN = () => {
  document.cookie = cookieNameTranslate + '=' + cookieValueTranslateEn + '; path=/';
  setTimeout(() => {
    translating();
  },1000);
};

const Translate = () => {
  useEffect(() => {
    translating();
  }, []);

  return (
    <div id='google_translate_element'></div>
  );
};

export default Translate;