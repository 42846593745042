import { combineReducers } from '@reduxjs/toolkit';
import reducerCountdown from '@/features/sliceCountdown';
import reducerPanel from '@/features/slicePanel';
import reducerSession from '@/features/sliceSession';
import reducerUser from '@/features/sliceUser';
import reducerUserData from '@/features/sliceUserData';
import reducerUserTable from '@/features/sliceUserTable';
import reducerCbtTable from '@/features/sliceCbtTable';
import reducerRcbtTable from '@/features/sliceRcbtTable';
import { genderCbtTableSlice, genderRcbtTableSlice } from '@/features/sliceGenderTable';
import reducerDataLatihTable from '@/features/sliceDataLatihTable';
import reducerDataUjiTable from '@/features/sliceDataUjiTable';
import reducerDataModelTable from '@/features/sliceDataModelTable';
import reducerCbtComponentTable from '@/features/sliceCbtComponentTable';
import reducerMitigasiAdaptasiCobenefit from '@/features/sliceMitigasiAdaptasiCobenefit';

const rootReducer = combineReducers({
  countdown: reducerCountdown,
  panel: reducerPanel,
  session: reducerSession,
  user: reducerUser,
  user_data: reducerUserData,
  user_table: reducerUserTable,
  cbt_table: reducerCbtTable,
  rcbt_table: reducerRcbtTable,
  cbt_gender_table: genderCbtTableSlice.reducer,
  rcbt_gender_table: genderRcbtTableSlice.reducer,
  data_latih_table: reducerDataLatihTable,
  data_uji_table: reducerDataUjiTable,
  data_model_table: reducerDataModelTable,
  data_cbt_component_table: reducerCbtComponentTable,
  mitigasi_adaptasi_cobenefit_table: reducerMitigasiAdaptasiCobenefit,
});

export default rootReducer;