import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/app/store';
import { setSession } from '@/features/sliceSession';

const cookie = process.env.REACT_APP_COOKIE || '';
export const defaultExpired = 3;
const epochFormat = 24 * 60 * 60 * 1000;

export const calculateEpoch = (date: number, days: number) => {
  return date + days * epochFormat;
};

export const useSessionName = () => {
  return cookie;
};

export const useSetSessionExpire = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentDate = useMemo(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now.getTime();
  }, []);
  const fn = (days: string | number | null = defaultExpired) => {
    dispatch(setSession({ login: { epoch: days === 'lifetime' ? 'lifetime' : typeof days === 'number' ? calculateEpoch(currentDate, days) : null } }));
  };
  return fn;
};

export const useGetSessionExpire = () => {
  return useSelector((state: RootState) => state.session.login?.epoch) || null;
};