import { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AnimatePresence, motion } from 'framer-motion';
import useHtmlClass from '@/hooks/useHtmlClass';
import close from '@/assets/icon/close.svg';

interface PopupProps {
  name: string;
  isOpen: boolean;
  isOverlayClose?: boolean;
  hasHeader?: boolean;
  closeButton?: boolean | string;
  closeModal: () => void;
  children: ReactNode;
}

const Popup: React.FC<PopupProps> = ({ name, isOpen, isOverlayClose = true, hasHeader, closeButton, closeModal, children }) => {
  const [showModal, setShowModal] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setShowModal(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setShowModal(false);
    setTimeout(() => {
      closeModal();
    }, 200);
  };

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isOverlayClose) {
      if (event.target === event.currentTarget) {
        handleClose();
      }
    }
  };

  useHtmlClass('overflow-hidden', isOpen);

  return (
    <AnimatePresence>
      {showModal && (
        <Modal
          isOpen={isOpen}
          onRequestClose={handleClose}
          contentLabel={name}
          appElement={document.getElementById('root') as HTMLElement}
          className="fixed inset-0 flex items-center justify-center"
          overlayClassName="fixed inset-0 z-30"
          shouldCloseOnOverlayClick={false}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed inset-0 bg-black/80 z-40"
            onClick={handleOverlayClick}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.2 }}
            className='w-full max-h-full overflow-auto scrollbar-hidden p-4 z-50'
            onClick={handleOverlayClick}
          >
            <section className="bg-white rounded-xl md:rounded-2xl px-4 py-8 md:px-6 lg:px-8 max-w-xl mx-auto relative">
              {children}
              {(closeButton !== false) &&
                <button type='button' onClick={handleClose} className={`popup-close absolute ${hasHeader ? 'top-4 right-4 md:top-5 md:right-5 lg:top-6 lg:right-6' : 'top-3 right-3'} ${closeButton === 'hide' ? 'sr-only' : ''}`}>
                  <img src={close} alt="icon" width={20} height={20} loading="lazy" />
                  <span className="sr-only">Tutup</span>
                </button>
              }
            </section>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default Popup;