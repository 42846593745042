import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionState {
  login: {
    epoch: string | number | null;
  };
  modal: {
    approve: {
      cbt: boolean;
      rcbt: boolean;
      gender: boolean;
    };
  };
};

const initialState: SessionState = {
  login: {
    epoch: null,
  },
  modal: {
    approve: {
      cbt: true,
      rcbt: true,
      gender: true,
    },
  },
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Partial<SessionState>>) => {
      return { ...state, ...action.payload };
    },
    clearSession: (state) => {
      return initialState;
    },
  },
});

export const { setSession, clearSession } = sessionSlice.actions;
export default sessionSlice.reducer;