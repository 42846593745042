import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserType = {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  nip: string;
  nik: string;
  is_google: boolean;
  status: string;
  type: {
    id?: string;
    name: string;
    value: string;
    image?: string;
  };
  role: string;
  latest_update: string;
  nama_institusi?: string;
  alasan_keperluan?: string;
}

type Pagination = {
  total: number;
  per_page: number;
  current_page: number;
}

type UserTableState = {
  name: boolean;
  role: boolean;
  contact: boolean;
  type: boolean;
  identity: boolean;
  latest_update: boolean;
  status: boolean;
  get_again: number;
  data: UserType[];
  pagination: Pagination;
};

const initialState: UserTableState = {
  name: true,
  role: true,
  contact: true,
  type: true,
  identity: true,
  status: true,
  latest_update: true,
  get_again: 0,
  data: [],
  pagination: {
    total: 0,
    per_page: 10,
    current_page: 1,
  },
};

const userTableSlice = createSlice({
  name: 'user_table',
  initialState,
  reducers: {
    setUserTable: (state, action: PayloadAction<Partial<UserTableState>>) => {
      return {
        ...state,
        ...action.payload,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    },
    clearUserTable: (state) => {
      return {
        ...initialState,
        pagination: {
          ...initialState.pagination,
        },
      };
    },
  },
});

export const { setUserTable, clearUserTable } = userTableSlice.actions;
export default userTableSlice.reducer;