import { createTheme } from '@mantine/core';

const label = {
  fontWeight: '400',
  marginBottom: '0.35rem',
}

export const theme = createTheme({
  fontFamily: 'Poppins, sans-serif',
  colors: {
    general: [
      '#F8FAFC',
      '#F1F5F9',
      '#E2E8F0',
      '#CBD5E1',
      '#94A3B8',
      '#64748B',
      '#475569',
      '#334155',
      '#1E293B',
      '#0F172A',
    ],
    primary: [
      '#F0F8FF',
      '#DFEFFF',
      '#B9DFFE',
      '#7BC7FE',
      '#34ABFC',
      '#0A91ED',
      '#0071CB',
      '#005FAD',
      '#054D87',
      '#01347C',
    ],
    success: [
      '#F3FEF8',
      '#E7FDF1',
      '#C5FBE3',
      '#A3F9D5',
      '#5FF5B1',
      '#50C793',
      '#3F9A7A',
      '#2E6D61',
      '#1F4B47',
      '#0F2A2E',
    ],
    info: [
      '#F3FEFF',
      '#E7FEFF',
      '#C5FDFF',
      '#A3FCFF',
      '#5FF9FF',
      '#0CE7FA',
      '#00B8D4',
      '#007A8D',
      '#005E67',
      '#003F42',
    ],
    warning: [
      '#FFFAF8',
      '#FFF4F1',
      '#FEE4DA',
      '#FDD2C3',
      '#FCB298',
      '#FA916B',
      '#DF8260',
      '#965741',
      '#714231',
      '#4A2E21',
    ],
    danger: [
      '#FFF7F7',
      '#FEEFEF',
      '#FCD6D7',
      '#FABBBD',
      '#F68B8D',
      '#F1595C',
      '#D75052',
      '#913638',
      '#6D292A',
      '#461A1B',
    ],
  },
  components: {
    Menu: {
      styles: (theme: any) => ({
        dropdown: {
          borderColor: theme.colors.general[1],
          borderRadius: '.5rem',
          padding: '0',
        },
        item: {
          padding: '.75rem 1rem',
          borderRadius: '0',
          color: theme.colors.general[6],
        },
      })
    },
    Input: {
      styles: (theme: any) => ({
        input: {
          fontSize: '1rem',
          lineHeight: '1',
          border: `1px solid ${theme.colors.general[3]}`,
          borderRadius: '.5rem',
          paddingTop: '.719rem',
          paddingBottom: '.719rem',
          width: '100%',
          minWidth: '70px',
          backgroundColor: 'white',
          backgroundImage: 'none',
        },
        label: label,
      }),
    },
    TextInput: {
      styles: (theme: any) => ({
        label: label,
      }),
    },
    Textarea: {
      styles: (theme: any) => ({
        input: {
          paddingTop: '1rem',
          paddingBottom: '1rem',
        },
        label: label,
      }),
    },
    Select: {
      styles: (theme: any) => ({
        dropdown: {
          borderRadius: '.5rem',
          padding: '.5rem',
        },
        label: label,
      }),
    },
    MultiSelect: {
      styles: (theme: any) => ({
        dropdown: {
          borderRadius: '.5rem',
          padding: '.5rem',
        },
        label: label,
      }),
    },
    Checkbox: {
      styles: (theme: any) => ({
        input: {
          cursor: 'pointer',
        },
        label: {
          cursor: 'pointer',
        },
        labelWrapper: {
          width: '100%',
        },
      }),
    },
    Radio: {
      styles: (theme: any) => ({
        radio: {
          cursor: 'pointer',
        },
        label: {
          cursor: 'pointer',
          fontSize: '1rem',
          paddingInlineStart: '0.5rem',
        },
      }),
    },
    RadioGroup: {
      styles: (theme: any) => ({
        label: label,
      }),
    },
    Modal: {
      styles: (theme: any) => ({
        body: {
          padding: '2rem',
        },
      }),
    },
    Switch: {
      styles: (theme: any) => ({
        root: {
          border: '2px solid white',
          borderRadius: '50px',
          width: 'fit-content',
        },
        track: {
          cursor: 'pointer',
          justifyContent: 'center',
        },
        trackLabel: {
          fontSize: '14px',
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        },
        thumb: {
          border: 'none',
        },
      }),
    },
    Popover: {
      styles: (theme: any) => ({
        dropdown: {
          padding: '1.5rem 1.25rem',
          borderRadius: '1rem',
        },
      }),
    },
  }
});