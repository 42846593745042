import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    Tawk_API?: {
      onLoad?: (callback: () => void) => void;
      setAttributes?: (attributes: Record<string, any>, callback?: (error?: any) => void) => void;
      addEvent?: (eventName: string, data: Record<string, any>) => void;
    };
    Tawk_LoadStart?: Date;
  }
}

interface TawkToWidgetProps {
  user?: {
    name: string;
    email: string;
    id: string;
  };
}

const TawkToWidget: React.FC<TawkToWidgetProps> = ({ user }) => {
  const location = useLocation();

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/67de8458f501c8190ca240fe/1imuj9vfm';
    script.async = true;
    script.setAttribute('crossorigin', '*');
    document.body.appendChild(script);

    script.onload = () => {
      if (window.Tawk_API?.onLoad) {
        window.Tawk_API.onLoad(() => {
          if (user) {
            window.Tawk_API?.setAttributes?.({
              name: user.name,
              email: user.email,
              userId: user.id,
            }, (error) => {
              if (error) console.error('Error setting attributes:', error);
            });
          }

          window.Tawk_API?.addEvent?.('user-navigation', {
            page: location.pathname,
          });
        });
      }
    };
  }, [user, location.pathname]);

  useEffect(() => {
    if (window.Tawk_API?.setAttributes) {
      window.Tawk_API.setAttributes({
        currentPage: location.pathname
      }, (error) => {
        if (error) console.error('Error updating page attributes:', error);
      });
    }
  }, [location]);

  return null;
};

export default TawkToWidget;